export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL';
export const SET_ADMIN = 'SET_ADMIN';

export const REQUEST_METRICS = 'REQUEST_METRICS';
export const RECEIVE_METRICS = 'RECEIVE_METRICS';
export const RECEIVE_MATERIALS = 'RECEIVE_MATERIALS';
export const RECEIVE_COLORS = 'RECEIVE_COLORS';
export const RECEIVE_CAVITIES = 'RECEIVE_CAVITIES';
export const RECEIVE_TEXTURES = 'RECEIVE_TEXTURES';
export const RECEIVE_ADDITIVES = 'RECEIVE_ADDITIVES';
export const GET_UPLOAD_MODEL_URL = 'GET_UPLOAD_MODEL_URL';
export const RENDER_MODEL_FILE = 'RENDER_MODEL_FILE';
export const RENDER_MODEL_META_DATA = 'RENDER_MODEL_META_DATA';

export const SUBMIT_QUOTE = 'SUBMIT_QUOTE';
