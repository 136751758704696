import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL
} from '../types';

import { API_URL } from '../consts';

// LOAD USER
export const loadUser = () => (dispatch, getState) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Request Body
  const body = { 
    token: getState().auth.token
  };

  if (body.token) {
    axios
      .post(`${API_URL}/api/verify-token/`, body, config)
      .then(res => {
        dispatch({
          type: LOAD_USER_SUCCESS
        });
      })
      .catch(err => {
        dispatch({
          type: LOAD_USER_FAIL
        });
      });
  }
  else {
    dispatch({
      type: LOAD_USER_FAIL
    });
  }
};

// LOGIN USER
export const login = (username, password) => dispatch => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Request Body
  const body = { username, password };

  axios
    .post(`${API_URL}/api/obtain-token/`, body, config)
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: LOGIN_FAIL
      });
    });
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  dispatch({ type: LOGOUT_SUCCESS });
};

// Setup config with token - helper function
export const tokenConfig = getState => {
  // Get token from state
  const {token} = getState().auth;

  // Headers
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  // If token, add to headers config
  if (token) {
    headers.Authorization = `JWT ${token}`;
  }

  return headers;
};
