import { combineReducers } from 'redux';
import {
  REQUEST_METRICS,
  RECEIVE_METRICS,
  RECEIVE_MATERIALS,
  RECEIVE_COLORS,
  RECEIVE_CAVITIES,
  RECEIVE_TEXTURES,
  RECEIVE_ADDITIVES,
  GET_UPLOAD_MODEL_URL,
  RENDER_MODEL_FILE,
  RENDER_MODEL_META_DATA
} from '../types';

import Auth from './auth';


function getMetrics(
  state = {
    isFetching: false,
    didInvalidate: false,
    metrics: {
    }
  },
  action
) {
  switch (action.type) {
    case REQUEST_METRICS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_METRICS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        metrics: action.outputData,
        lastUpdated: action.receivedAt
      })
        
    default:
      return state
  }
}

function getBaseData(
  state = {
    baseData: {}
  },
  action
) {
  switch (action.type) {
    case RECEIVE_MATERIALS:
      return Object.assign({}, state, {
        baseData: {
          ...state.baseData,
          materials: action.outputData
        }
      })
    case RECEIVE_COLORS:
      return Object.assign({}, state, {
        baseData: {
          ...state.baseData,
          colors: action.outputData
        }
      })
    case RECEIVE_CAVITIES:
      return Object.assign({}, state, {
        baseData: {
          ...state.baseData,
          cavities: action.outputData
        }
      })
    case RECEIVE_TEXTURES:
      return Object.assign({}, state, {
        baseData: {
          ...state.baseData,
          textures: action.outputData
        }
      })
    case RECEIVE_ADDITIVES:
      return Object.assign({}, state, {
        baseData: {
          ...state.baseData,
          additives: action.outputData
        }
      })

    default:
      return state
  }
}

function getModel(
  state = {
    modelUrl: '',
    modelFile: null
  },
  action
) {
  switch (action.type) {
    case GET_UPLOAD_MODEL_URL:
      return Object.assign({}, state, {
        modelUrl: action.outputData,
      })
    
    case RENDER_MODEL_FILE:
      return Object.assign({}, state, {
        modelFile: action.outputData,
      })
    
    case RENDER_MODEL_META_DATA:
      return Object.assign({}, state, {
        modelMetaData: action.outputData
      })

    default:
      return state
  }
}

function metricsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_METRICS:
    case REQUEST_METRICS:
      return Object.assign({}, state, {
        ...getMetrics(state, action)
      })
    default:
      return state
  }
}

function baseDataReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_MATERIALS:
    case RECEIVE_COLORS:
    case RECEIVE_CAVITIES:
    case RECEIVE_TEXTURES:
    case RECEIVE_ADDITIVES:
      return Object.assign({}, state, {
        ...getBaseData(state, action)
      })
    default:
      return state
  }
}

function modelReducer(state = {}, action) {
  switch (action.type) {
    case GET_UPLOAD_MODEL_URL:
    case RENDER_MODEL_FILE:
    case RENDER_MODEL_META_DATA:
      return Object.assign({}, state, {
        ...getModel(state, action)
      })

    default:
      return state
  }
}

const rootReducer = combineReducers({
    metricsReducer,
    baseDataReducer,
    modelReducer,
    auth: Auth
})

export default rootReducer;
