import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  SET_ADMIN
} from '../types';

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  message: '',
  isAdmin: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        message: ''
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        message: ''
      };
    case SET_ADMIN:
      return {
        ...state,
        isAdmin: action.payload
      };
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        message: 'Login failed. Try again.'
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false
      };
    case LOAD_USER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false
      };
    default:
      return state;
  }
}
